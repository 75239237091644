export const environment = {
  base : '/',
  production: false,
  env: '2',
  captcha: {
    siteKey: '6LfyS3kUAAAAAIqLGCDROH4pXHpVfteQ7cdH7Xve'
  },
  baseUrl: 'https://api.servicios.tst.pluxee.cl/connect',
  tradeSearchEngine: 'https://sta.pluxee.cl/merchant-finder/es-CL?pluxeeProducts=JUNAEB&merchantTypes=PHYSICAL_AND_ECOM%7CPHYSICAL',
  urlCreateCase: 'https://casos.tst.pluxee.cl/',
  urlMyCases: 'https://casos.tst.pluxee.cl/estado-caso',

  datadog: {
    enable: true,
    token: 'pub4cd0b996cf955ecb3d088dc33fab78f4',
    applicationId: '4cf8c139-be9e-4313-847e-0f9e5fa0d4cf',
    service: 'becajunaeb',
    env: 'test'
  },
  connect: {
    clientId: '40ed40cf-0a7f-429f-897a-aa68c62c8f99',
    authUrl: 'https://connect.sta.pluxee.app/op/oidc/auth',
    redirectAuthUrl: 'https://becajunaeb.tst.pluxee.cl/login',
    resource: 'https://api.servicios.tst.pluxee.cl/connect/',
    tokenUrl: 'https://connect.sta.pluxee.app/op/oidc/token',
    account: 'https://connect.sta.pluxee.app/am/cl/profile',
    logoutUrl: 'https://connect.sta.pluxee.app/op/oidc/session/end',
    redirectLogoutUrl: 'https://becajunaeb.tst.pluxee.cl/logout',
    changePin: 'https://consumidores.tst.pluxee.cl/connect/pin/redirect',
    register: 'https://consumidores.tst.pluxee.cl/connect/account/verify?platform=junaeb'
  },
  chatbot: {
    url: 'https://apps.mypurecloud.com/genesys-bootstrap/genesys.min.js',
    environment: 'use1',
    deploymentId: '326f1f93-bc68-4c9c-ad46-393fe2e649b9',
  },
  seguimientoSolicitudUrlClickOh: 'https://console.dev.clicoh.com/monitor'
};
