import { Component, OnInit, ViewChild } from '@angular/core';
import { HeaderComponent } from '../../shared/footer/header/header.component';
import {Faq} from '../../models/faq.model';



@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  @ViewChild(HeaderComponent, {static: true}) headerComponent: HeaderComponent;
  faqs: Array<Faq> = [];

  constructor() { }

  ngOnInit() {
    const urlBeca = 'https://becajunaeb.pluxee.cl/';
    // tslint:disable-next-line:no-unused-expression
    this.headerComponent.menuActive = 'm_sobre-la-beca';
    this.faqs = [
      {
        title: '¿Cómo accedo a las plataformas de Pluxee?',
        description: `<p> <strong>Si no estás registrado en los portales de Pluxee</strong> </p>
          <p>1. Debes descargar la app Pluxee Chile o ingresar a <a class="inline-link" href="${urlBeca}" target="_blank" rel="noopener">${urlBeca}</a> y crear tu cuenta.</p>
          <p>2. Ingresa a <a class="inline-link" href="${urlBeca}" target="_blank" rel="noopener">${urlBeca}</a> y selecciona Acceso > beneficiarios, clic en acceder.</p>
          <p>3. Selecciona <strong>¿No tienes una cuenta? Registrarse</strong>.</p>
          <p>4. Introduce tu dirección de correo electrónico y, a continuación, hacer clic en el botón <strong>Crear mi cuenta</strong>.</p>
          <p>5. Revisa tu bandeja de entrada: te enviaremos un correo que incluye un código de verificación de seis dígitos. <strong>Este código solo es válido durante cinco minutos</strong>. Te recomendamos revisar la bandeja de spam.</p>
          <p>6. Ingresa el código y presionar el botón <strong>Continuar</strong>.</p>
          <p>7. Crea tu contraseña.</p>
          <p>8. Completar los datos de su perfil, con tu, nombre(s), apellido(s), fecha de nacimiento y número de teléfono móvil.</p>
          <p>9. Recibirás un SMS con un código de verificación de seis dígitos en el número que proporcionaste en tu perfil, haz clic en <strong>Continuar</strong>.</p>
          <p>10. Ingresa tu rut y número de documento válidos</p>
          <p>¡Listo! Ya puedes ingresar con tu cuenta a la app Pluxee Chile o el portal.</p>

          <p><strong>Si ya eras un usuario registrado en los portales de Pluxee</strong></p>
          <p>1. Ingresa a tu app Pluxee Chile o a <a class="inline-link" href="${urlBeca}" target="_blank" rel="noopener">${urlBeca}</a> con tu mail.</p>
          <p>2. Revisa tu bandeja de entrada: te enviaremos un correo que incluye un código de verificación de seis dígitos. <strong>Este código solo es válido durante cinco minutos</strong>. Te recomendamos revisar la bandeja de spam.</p>
          <p>3. Ingresa el código y presionar el <strong>botón Continuar</strong>. ¡Listo! Ya puedes ingresar a las plataformas.</p>
          <p>4. Si haz iniciado sesión anteriormente en tu equipo, solo necesitas tu email y contraseña para ingresar.</p>

          <p>¡Ojo! Tendrás 2 claves, la Contraseña Segura para acceder a tus plataformas y el PIN de Compra para validar tus transacciones.</p>
`
      },
      {
        title: '¿Cómo activo la tarjeta?',
        description: `
            <p><strong>Si ya eras un usuario registrado en los portales de Pluxee:</strong></p>
            <ul style="list-style-type: circle">
              <li>Ingresa a tu app Pluxee Chile o a <strong><a class="inline-link" href="${urlBeca}" target="_blank" rel="noopener">${urlBeca}</a></strong> con tu correo electrónico y contraseña segura.</li>
              <li>Haz clic en <strong>Activa tu tarjeta</strong> en tu perfil, selecciona enviar código verificador (el cual es alfanumérico y de 6 dígitos), revisa tu bandeja de entrada del correo y/o de spam, cópialo y pégalo en tu app Pluxee Chile, y crea tu PIN de 4 dígitos.</li>
              <li>¡Listo! Tu tarjeta y PIN de compra se encuentran activos.</li>
            </ul>
            <p><strong>Si no estás registrado en los portales de Pluxee:</strong></p>
            <ul style="list-style-type: circle">
              <li>Debes descargar nuestra app Pluxee Chile o ingresar a <strong><a class="inline-link" href="${urlBeca}" target="_blank" rel="noopener">${urlBeca}</a></strong> y crear tu cuenta. Para saber más sobre cómo hacerlo revisa la pregunta <strong>¿Cómo accedo a las plataformas de Pluxee?</strong></li>
              <li>Una vez en tu app Pluxee Chile o en la web, haz clic en <strong>Activar tarjeta</strong> en tu perfil y procede a crear tu PIN de compra.</li>
              <li>Selecciona enviar código verificador en tu perfil (el cual es alfanumérico y de 6 dígitos), revisa tu bandeja de entrada de correo electrónico y/o de spam, cópialo y pégalo en tu app Pluxee Chile, y crea tu PIN de 4 dígitos.</li>
              <li>¡Listo! Tu tarjeta y PIN de compra se encuentran activos.</li>
            </ul>
            <p>¡Ojo! Tendrás 2 claves, la Contraseña Segura para acceder a tus plataformas y el PIN de Compra para validar tus transacciones.</p>
          `
      },
      {
        title: '¿Cómo saber mi saldo?',
        description: `Puedes conocer tu saldo ingresando a tu app Pluxee Chile o en nuestra web  <a class="inline-link" href="${urlBeca}" target="_blank" rel="noopener">${urlBeca}</a>
en <strong>>Acceso >Beneficiarios</strong> con tu correo electrónico registrado y tu contraseña, o llamando al número 800 646 155.`
      },
      {
        title: '¿Qué es y cómo se usa la Clave Dinámica?',
        description: `
          <p>La clave dinámica es una clave aleatoria, segura y de un solo uso, que dura máximo 90 minutos. Esta clave, que contiene 4 dígitos, permite el pago directo en la caja de nuestros asociados, ¡con ella no necesitas tu tarjeta física!</p>
          <p>Consulta al comercio si opera con este medio de pago.</p>
          <p>Para obtener tu clave dinámica tienes 3 alternativas:</p>
          <ul style="list-style-type: circle">
            <li><strong>Desde tu app Pluxee Chile:</strong> Selecciona la opción <strong>Clave Dinámica</strong> e ingresa tu PIN de compra (contraseña secreta de 4 dígitos que creaste junto con tu cuenta).</li>
            <li><strong>Desde la Web:</strong> Selecciona la opción <strong>Pago</strong> que está en la parte superior derecha, luego <strong>Pago Clave Dinámica</strong>, ingresa tu PIN de compra (contraseña secreta de 4 dígitos que creaste junto a tu cuenta).</li>
            <li><strong>Llamando al número 800 646 155</strong>: Ingresa tu Rut y PIN de compra (contraseña secreta de 4 dígitos que creaste junto a tu cuenta), y luego selecciona la opción 1.</li>
          </ul>
        `
      },
      {
        title: '¿Qué es y cómo se usa Pago QR?',
        description: `
          <p>Paga con QR, de manera simple, segura y rápida.</p>
          <p>Consulta al comercio si opera con este medio de pago.</p>
          <ul style="list-style-type: circle">
            <li>Ingresa a tu app Pluxee Chile y selecciona la opción <strong>Pago QR</strong>.</li>
            <li><strong>Escanea el código</strong> entregado por el comercio y confirma con tu PIN de compra (contraseña secreta de 4 dígitos que creaste junto a tu cuenta).</li>
          </ul>
          <p>¡Listo! Con Pago QR no necesitas tu tarjeta.</p>
        `
      },
      {
        title: '¿Qué es y cómo se usa Pago Online?',
        description: `
          <p>Es una forma de pago con la que puedes pagar tu compra desde tu app Pluxee Chile o ingresando a <strong><a class="inline-link" href="${urlBeca}" target="_blank" rel="noopener">${urlBeca}</a></strong>.</p>
          <p>Consulta al comercio si opera con este medio de pago.</p>
          <p>Para usarlo debes:</p>
          <ul style="list-style-type: circle">
            <li>Ingresa a tu app Pluxee Chile o <strong><a class="inline-link" href="${urlBeca}" target="_blank" rel="noopener">${urlBeca}</a></strong> en <strong>Acceso > Beneficiarios</strong> con tu correo electrónico registrado y tu contraseña.</li>
            <li>Selecciona la opción <strong>Pago Online</strong>.</li>
            <li>Ingresa el código proporcionado por el comercio.</li>
            <li>Verifica que el local seleccionado sea el mismo al que quieres realizar el pago.</li>
            <li>Ingresa monto y valida la transacción con tu PIN de Compra (contraseña secreta de 4 dígitos que creaste junto a tu cuenta).</li>
            <li>¡Listo! Aparecerá tu comprobante de compra en tu pantalla y el comercio podrá revisarla en su sistema.</li>
          </ul>
          <p>*El comercio puede solicitar el reenvío del comprobante de pago a un correo electrónico o a veces querrá tomar una foto del comprobante de la transacción para su respaldo.</p>
        `
      },
      {
        title: '¿Cómo saber cuál es el operador de mi BAES?',
        description: `<p>A partir de 2025, Pluxee opera la beca de todos los estudiantes de la Región Metropolitana.
En caso de que tu casa de estudios se encuentre en otra región, debes dirigirte con el encargado de tu centro de estudios y solicitar esta información o directamente a Junaeb.
</p>`
      },
      {
        title: '¿Cómo recupero mi contraseña?',
        description: `
          <p>Si olvidas tu clave, la puedes recuperar en tu app Pluxee Chile, opción <strong>¿Olvidaste tu Contraseña?</strong> o ingresando a <strong><a class="inline-link" href="${urlBeca}" target="_blank" rel="noopener">${urlBeca}</a></strong>, en <strong>Acceso > Beneficiarios</strong> con tu correo electrónico, y seleccionando <strong>¿Olvidaste tu contraseña?</strong></p>
          <p>Al seleccionar esta opción, automáticamente se envía un código de verificación a tu correo, ingrésalo y elige tu nueva contraseña.</p>
          <p>Si olvidaste tu correo, contáctanos a través de nuestros canales oficiales:</p>
          <ul style="list-style-type: circle">
            <li>En <strong><a class="inline-link" href="${urlBeca}" target="_blank" rel="noopener">${urlBeca}</a></strong></li>
            <li>A través de nuestro correo electrónico <strong>junaeb.cl@pluxeegroup.com</strong></li>
            <li>O al <strong>800 646 155</strong>.</li>
          </ul>
        `
      },
      {
        title: '¿Cuándo se cargará el monto mensual de mi BAES y cuál es su vigencia?',
        description: `
            <p>Los saldos se liberan el 1° de cada mes (con excepción de marzo) y vencen los 5 del mes siguiente (se incluyen sábados, domingos y festivos).</p>
            <p>Luego del vencimiento, el saldo restante se eliminará y ya no estará disponible para ser utilizado, ¡no olvides usarlo junto a las mejores promociones y comercios que tendremos disponibles para ti!</p>
          `
      },
      {
        title: 'No me cargaron mi saldo, ¿cuáles pueden ser los motivos?',
        description: `
          <p>Los motivos de no carga de saldo pueden ser:</p>
          <ul style="list-style-type: circle">
            <li>No eres alumno regular, es decir, congelaste tu carrera, te retiraste, egresaste o excediste el tiempo máximo de adjudicación.</li>
            <li>No fuiste confirmado por tu centro de estudios.</li>
            <li>No tienes adjudicada la BAES en los registros actuales.</li>
          </ul>
          <p><strong>Recuerda que Pluxee no asigna la BAES</strong>, solo la opera, por lo tanto, debes consultar tu situación académica en tu casa de estudios.</p>
        `
      },
      {
        title: '¿Cuáles son las restricciones de compra con mi BAES?',
        description: `
          <p>En términos generales, los productos que <strong>no</strong> se pueden comprar con la BAES son:</p>
          <ul style="list-style-type: circle">
            <li>Bebidas alcohólicas.</li>
            <li>Cigarrillos.</li>
            <li>Fármacos.</li>
            <li>Productos que no pertenezcan a la categoría Alimentos.</li>
          </ul>
          <p>Sumado a esto, para la BAES, existen distintas restricciones, dependiendo del tipo de comercio asociado.</p>
          <ul style="list-style-type: circle">
            <li><strong>En supermercados:</strong> Podrás comprar solo alimentos con hasta 2 sellos de advertencia "Alto en" de la ley 20.606.</li>
            <li><strong>Cadenas de comida:</strong> Sólo podrás comprar los menús saludables aprobados por JUNAEB y publicados en la sala de ventas.</li>
          </ul>
        `
      },
      {
        title: '¿Cómo postulo a la BAES?',
        description: `
          <p>La BAES no tiene una postulación aparte, sino que se entrega automáticamente a aquellos estudiantes que obtienen algunas de las becas de arancel y pertenecen a los deciles 1 al 6 (quintiles 1 al 3).</p>
          <p>Para más información, ingresa a <strong><a class="inline-link" href="https://www.junaeb.cl/beca-alimentacion-la-educacion-superior" target="_blank" rel="noopener">https://www.junaeb.cl/beca-alimentacion-la-educacion-superior</a></strong></p>
        `
      },
      {
        title: '¿Cuáles son mis deberes y derechos como beneficiario de la BAES?',
        description: `
          <p>Los deberes como beneficiarios de la BAES, están establecidos por JUNAEB.</p>
          <p>Revísalos en <strong><a class="inline-link" href="https://www.junaeb.cl/preguntas-frecuentes-becas-junaeb" target="_blank" rel="noopener">https://www.junaeb.cl/preguntas-frecuentes-becas-junaeb</a></strong></p>
        `
      },
      {
        title: '¿Qué hacer en caso de que un comercio asociado efectúe un cobro indebido, mal trato, abuso u otro contra un beneficiario BAES?',
        description: `
          <p>En caso de que quieras denunciar a un comercio asociado a la Red de Pluxee, tienes dos opciones:</p>
          <ul style="list-style-type: circle">
            <li>Ingresa a tu app Pluxee Chile, y en tu perfil sección <strong>Contáctanos</strong> envíanos tu denuncia.</li>
            <li>Dirígete al <strong>Centro de Ayuda</strong> en <strong><a class="inline-link" href="${urlBeca}" target="_blank" rel="noopener">${urlBeca}</a></strong> y escribe tu reclamo.</li>
          </ul>
        `
      },
      {
        title: '¿Dónde y cómo uso mi beneficio sin tarjeta?',
        description: `
          <p>Puedes usar tu beneficio sin tener la tarjeta en tus manos y sin contacto:</p>
          <ul style="list-style-type: circle">
            <li>En nuestra red de comercios, dictando tu <strong>Rut y Clave Dinámica</strong> (los comercios que tienen la máquina donde antiguamente se deslizaba tu tarjeta, pueden ingresarlo ahí). Puedes verificar en tu app Pluxee Chile, sección <strong>Comercios</strong> nuestra red de comercios disponibles y las formas de pago habilitadas en cada uno de los comercios).</li>
            <li>Usando <strong>Pago Online</strong> en tu app Pluxee Chile, en comercios habilitados presenciales o digitales (algunos incluso cuentan con despacho a domicilio).</li>
            <li>Usando <strong>Pago QR</strong>, en comercios habilitados presenciales.</li>
            <li>En comercios online adheridos a la red.</li>
          </ul>
        `
      },
      {
        title: '¿Puedo solicitar tarjeta física para hacer uso de mi beneficio?',
        description: `
          <p>Sí, aunque recuerda que la bandita Junabera es 100% digital y no quiere generar más impacto en el medio ambiente.</p>
          <p>Si aun así decides optar por tarjeta física, puedes solicitarla a través de nuestro <strong>portal web</strong> con su respectivo instructivo.</p>
          <p><a href="https://public.cdn.pluxee.cl/GUIA_TARJETA_JUNAEB.pdf" download>Haz click aquí para descargar el paso a paso.</a></p>
        `
      },
      {
        title: '¿Qué es y cómo se usa el Pin de Compra?',
        description: `
    <p>Es una clave de 4 dígitos que utilizas al momento de comprar con tu tarjeta en nuestros comercios asociados, también es la clave que ingresas al momento de realizar un pago online o solicitar clave dinámica.</p>
  `
      },
      {
        title: '¿Qué pasa si olvido el Pin de Compra?',
        description: `
    <p>De no recordar tu PIN de compras, puedes realizar el cambio seleccionando desde la app la opción <strong>cambiar PIN de compra</strong>, posterior a eso te enviaremos un código a tu correo para que ingreses en la app y tengas la opción de crear un nuevo PIN de compra, el cual debe cumplir con los siguientes requisitos:</p>
    <ul style="list-style-type: circle">
      <li>Debe ser dígitos no consecutivos.</li>
      <li>Puedes repetir un dígito un máximo de 3 veces.</li>
      <li><strong>Puedes repetir tu pin anterior las veces que requieras.</strong></li>
      <li>Tu PIN no puede corresponder a una porción del RUT.</li>
    </ul>
  `
      },
      {
        title: '¿Qué pasa si mi cédula está vencida?',
        description: `
    <p>Si tu cédula no se encuentra vigente, debes contactarte a través de la siguiente casilla: <strong>junaeb.cl@pluxeegroup.com</strong> enviando la siguiente información para que podamos habilitar tu cédula:</p>
    <ul style="list-style-type: circle">
      <li>Rut.</li>
      <li>Imagen de tu cédula por ambas partes.</li>
    </ul>
  `
      },
      {
        title: '¿Qué pasa si extravié mi cédula? ¿Qué pasos debo seguir?',
        description: `
    <p>Debes contactarte con nosotros a través de la siguiente casilla: <strong>junaeb.cl@pluxeegroup.com</strong>, enviando la siguiente información:</p>
    <ul style="list-style-type: circle">
      <li>Rut.</li>
      <li>Imagen de tu cédula por ambas partes.</li>
      <li>Adjuntar solicitud de la nueva cédula entregada por el registro civil, de esta manera validaremos si eres beneficiario y te podremos entregar los pasos a seguir.</li>
    </ul>
  `
      },
      {
        title: '¿Cómo puedo cambiar mi correo electrónico?',
        description: `
    <p>Para realizar la modificación de tu correo electrónico tenemos 2 alternativas:</p>
    <p><strong>1) Desde tu app Pluxee Chile:</strong></p>
    <ul style="list-style-type: circle">
      <li>Selecciona <strong>Configurar</strong> (esquina inferior derecha de pantalla).</li>
      <li>Selecciona <strong>Perfil</strong>.</li>
      <li>En contacto selecciona <strong>Editar</strong>.</li>
      <li>Editar correo electrónico.</li>
      <li>Agrega tu correo personal.</li>
      <li>Luego <strong>confirmar</strong>.</li>
      <li>Se enviará un código de validación al nuevo correo que estás agregando (revisa spam).</li>
      <li>Una vez agregado, selecciona los 3 puntos y establece como correo principal.</li>
      <li>Borra el correo anterior (corporativo).</li>
      <li>Cierra sesión y vuelve a ingresar para verificar que tu nuevo correo de ingreso corresponda al personal.</li>
    </ul>
    <p><strong>2) Si no tienes acceso a tu cuenta, Puedes solicitar el cambio de correo a través de la siguiente casilla <strong>junaeb.cl@pluxeegroup.com</strong> enviando esta información:</strong></p>
    <ul style="list-style-type: circle">
      <li>Rut.</li>
      <li>Nuevo correo.</li>
      <li>Instituto o universidad.</li>
      <li>Carrera.</li>
      <li>Año de ingreso.</li>
      <li>Imagen de tu cédula de identidad.</li>
    </ul>
  `
      },
      {
        title: '¿Cuáles son los canales de contacto?',
        description: `
    <p>Nuestros canales habilitados son:</p>
    <ul style="list-style-type: circle">
      <li><strong>Autoatención a través de IVR</strong>: a través del canal telefónico, contamos con un IVR de autoatención disponible 24/7 donde podrás realizar las siguientes consultas y gestiones:
        <ul style="list-style-type: square">
          <li>Solicitar clave dinámica</li>
          <li>Consultar últimas transacciones</li>
          <li>Consultar estado de la tarjeta</li>
          <li>Bloquear tarjeta</li>
          <li>Consultar saldo</li>
        </ul>
      </li>
      <li><strong>Atención telefónica gratuita</strong>:
        <ul style="list-style-type: square">
        <li>800 646 155</li>
        <li>(Lunes a Domingo, 8 AM - 8 PM)</li>
        </ul>
      </li>
      <li><strong>Chatbot</strong>: <a class="inline-link" href="${urlBeca}" target="_blank" rel="noopener">${urlBeca}</a></li>
      <li><strong>Módulo de Denuncia de Casos</strong>: <a class="inline-link" href="https://casos.pluxee.cl/" target="_blank" rel="noopener">https://casos.pluxee.cl/</a></li>
      <li><strong>App Pluxee Chile</strong>: Formulario de Contacto en tu perfil</li>
      <li><strong>Correo electrónico</strong>: <a class="inline-link" href="mailto:junaeb.cl@pluxeegroup.com">junaeb.cl@pluxeegroup.com</a></li>
    </ul>
  `
      },
      {
        title: '¿Cómo puedo bajar la App Pluxee Chile en mi dispositivo Huawei (sin playstore)?',
        description: `
    <p>Para descargar la app Pluxee Chile en tu dispositivo Huawei sin Play Store, debes instalar la app “AURORA” y en dicha App buscar Pluxee Chile.</p>
    <p>Para mayor información puedes dirigirte al siguiente link: <a class="inline-link" href="https://consumer.huawei.com/es/community/details/Aurora-Store-La-alternativa-Open-Source-a-Google-Play/topicId_40116/" target="_blank" rel="noopener">Guía de instalación Aurora Store</a></p>
  `
      }
    ];
  }

}
